import createI18nStore from './store'
import createGetTranslations from './create_get_translations'

/**
 * Returns URL to the locale files for specific country
 * @callback localesUrl
 * @param {string} Country
 */
/**
 * Debugging function
 * @callback debug
 * @param {string} Debug message
 * @param {object} Additional debugging data
 */
/**
 * Creates getTranslation function for fetching translations
 * @param {string} mapKey Project name from h2 phraseapp.yml
 * @param {localesUrl} localesUrl Function that returns URL to locale files for specific country
 * @param {object} localTranslations Inlined translations for minimal UI in case of an error
 * @param debug
 * @returns {function({country?: *}): null}
 */
export default ({ mapKey, localesUrl, localTranslations = {}, debug = () => {} }) => {
  const getTranslations = createGetTranslations({ mapKey, localesUrl, localTranslations, debug })
  return createI18nStore({ getTranslations, localTranslations })
}
