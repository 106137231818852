'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = require('react');
var React__default = _interopDefault(React);
var apolloLink = require('apollo-link');
var PropTypes = _interopDefault(require('prop-types'));
var changeEmitter = require('change-emitter');

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();







var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};



var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};











var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

var ApolloLinkNetworkStatus = function (_ApolloLink) {
  inherits(ApolloLinkNetworkStatus, _ApolloLink);

  function ApolloLinkNetworkStatus(_ref) {
    var store = _ref.store;
    classCallCheck(this, ApolloLinkNetworkStatus);

    var _this = possibleConstructorReturn(this, (ApolloLinkNetworkStatus.__proto__ || Object.getPrototypeOf(ApolloLinkNetworkStatus)).call(this));

    _this.store = store;
    return _this;
  }

  createClass(ApolloLinkNetworkStatus, [{
    key: 'request',
    value: function request(operation, forward) {
      var _this2 = this;

      this.store.onRequest({ operation: operation });
      var subscriber = forward(operation);

      return new apolloLink.Observable(function (observer) {
        var isPending = true;

        var subscription = subscriber.subscribe({
          next: function next(result) {
            isPending = false;
            _this2.store.onSuccess({ operation: operation, result: result });
            observer.next(result);
          },
          error: function error(networkError) {
            isPending = false;
            _this2.store.onError({ operation: operation, networkError: networkError });
            observer.error(networkError);
          },
          complete: observer.complete.bind(observer)
        });

        return function () {
          if (isPending) _this2.store.onCancel({ operation: operation });
          if (subscription) subscription.unsubscribe();
        };
      });
    }
  }]);
  return ApolloLinkNetworkStatus;
}(apolloLink.ApolloLink);

var NetworkStatusNotifier = function (_Component) {
  inherits(NetworkStatusNotifier, _Component);

  function NetworkStatusNotifier() {
    var _ref;

    var _temp, _this, _ret;

    classCallCheck(this, NetworkStatusNotifier);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = NetworkStatusNotifier.__proto__ || Object.getPrototypeOf(NetworkStatusNotifier)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      networkStatus: _this.props.initialNetworkStatus
    }, _temp), possibleConstructorReturn(_this, _ret);
  }

  createClass(NetworkStatusNotifier, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.unlisten = this.props.store.listen(function (networkStatus) {
        _this2.setState({ networkStatus: networkStatus });
      });
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.unlisten();
    }
  }, {
    key: 'render',
    value: function render() {
      return this.props.render(this.props.mapStateToProps(this.state.networkStatus));
    }
  }]);
  return NetworkStatusNotifier;
}(React.Component);

NetworkStatusNotifier.propTypes = {
  initialNetworkStatus: PropTypes.object.isRequired,
  mapStateToProps: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired
};

var NetworkStatusStore = function () {
  function NetworkStatusStore(_ref) {
    var _this = this;

    var initialState = _ref.initialState,
        reducers = _ref.reducers;
    classCallCheck(this, NetworkStatusStore);

    this.onRequest = function (action) {
      _this.updateState(_this.reducers.onRequest(_this.state, action));
    };

    this.onSuccess = function (action) {
      _this.updateState(_this.reducers.onSuccess(_this.state, action));
    };

    this.onError = function (action) {
      _this.updateState(_this.reducers.onError(_this.state, action));
    };

    this.onCancel = function (action) {
      if (_this.reducers.onCancel) {
        _this.updateState(_this.reducers.onCancel(_this.state, action));
      }
    };

    this.state = initialState;
    this.reducers = reducers;

    this.emitter = changeEmitter.createChangeEmitter();
  }

  createClass(NetworkStatusStore, [{
    key: 'updateState',
    value: function updateState(next) {
      if (next === this.state) return;

      this.state = next;
      this.emit();
    }
  }, {
    key: 'emit',
    value: function emit() {
      this.emitter.emit(this.state);
    }
  }, {
    key: 'listen',
    value: function listen(fn) {
      return this.emitter.listen(fn);
    }
  }]);
  return NetworkStatusStore;
}();

var isSubscriptionOperation = function isSubscriptionOperation(operation) {
  return operation.query.definitions.some(function (definition) {
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  });
};

var defaultConfig = {
  initialState: { numPendingRequests: 0, error: null },

  reducers: {
    onRequest: function onRequest(state, _ref) {
      var operation = _ref.operation;

      if (isSubscriptionOperation(operation) || operation.getContext().useNetworkStatusNotifier === false) {
        return state;
      }

      return {
        numPendingRequests: state.numPendingRequests + 1,
        error: null
      };
    },

    onSuccess: function onSuccess(state, _ref2) {
      var operation = _ref2.operation,
          result = _ref2.result;

      if (operation.getContext().useNetworkStatusNotifier === false) {
        return state;
      }

      return {
        numPendingRequests: isSubscriptionOperation(operation) ? state.numPendingRequests : state.numPendingRequests - 1,
        error: result.errors ? {
          graphQLErrors: result.errors,
          response: result,
          operation: operation
        } : null
      };
    },

    onError: function onError(state, _ref3) {
      var operation = _ref3.operation,
          networkError = _ref3.networkError;

      if (operation.getContext().useNetworkStatusNotifier === false) {
        return state;
      }

      return {
        numPendingRequests: isSubscriptionOperation(operation) ? state.numPendingRequests : state.numPendingRequests - 1,
        error: {
          graphQLErrors: networkError.result ? networkError.result.errors : undefined,
          networkError: networkError,
          operation: operation
        }
      };
    },

    onCancel: function onCancel(state, _ref4) {
      var operation = _ref4.operation;

      if (isSubscriptionOperation(operation) || operation.getContext().useNetworkStatusNotifier === false) {
        return state;
      }

      return {
        numPendingRequests: state.numPendingRequests - 1
      };
    }
  },

  mapStateToProps: function mapStateToProps(state) {
    return {
      loading: state.numPendingRequests > 0,
      error: state.error
    };
  }
};

function createNetworkStatusNotifier() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultConfig;

  if (!config.reducers) {
    throw new Error('`reducers` are mandatory.');
  }

  if (!config.mapStateToProps) {
    config = _extends({}, config, { mapStateToProps: function mapStateToProps(state) {
        return state;
      } });
  }

  var store = new NetworkStatusStore(config);

  var ConfiguredNetworkStatusNotifier = function ConfiguredNetworkStatusNotifier(props) {
    return React__default.createElement(NetworkStatusNotifier, _extends({}, props, {
      initialNetworkStatus: config.initialState,
      mapStateToProps: config.mapStateToProps,
      store: store
    }));
  };

  return {
    link: new ApolloLinkNetworkStatus({ store: store }),
    NetworkStatusNotifier: ConfiguredNetworkStatusNotifier
  };
}

exports.createNetworkStatusNotifier = createNetworkStatusNotifier;
