import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons'
import styled from 'styled-components/native'
import { BLACK } from '../../constants/colors/brand'

const IconContainer = styled(View)`
  width: 24px;
  height: 24px;
  justify-content: center;
`

const Icon = ({ name, size = 24, color = BLACK, style = {}, set = 'MaterialIcons', innerRef, ...rest }) => {
  const sizeStyle = { width: size, height: size }

  switch (set) {
    case 'MaterialCommunityIcons':
      return (
        <IconContainer style={[sizeStyle, style]} ref={innerRef}>
          <MaterialCommunityIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      )
    case 'MaterialIcons':
    default:
      return (
        <IconContainer style={[sizeStyle, style]} ref={innerRef}>
          <MaterialIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      )
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  set: PropTypes.oneOf(['MaterialIcons', 'MaterialCommunityIcons', undefined, null]),
  innerRef: PropTypes.func
}

export default Icon
