import { Settings as LuxonSettings } from 'luxon'
import i18nJS from 'i18n-js'
import { flow, types, getRoot, unprotect, protect } from 'mobx-state-tree'
import updateLuxonInstances from './update_luxon_instances'
import { languageCodes } from '../constants/locales'
import { Platform } from 'react-native'
import loadIntlPolyfill from './intl_polyfill'

const createI18nStore = ({ getTranslations, localTranslations }) => {
  const i18nStore = types
    .model('i18nStore', {
      locale: types.optional(types.string, 'en'),
      availableLocales: types.optional(types.array(types.string), ['en'])
    })
    .volatile(self => ({
      i18n: i18nJS
    }))
    .views(self => ({
      t(name, values) {
        return self.i18n.t(name, { ...values, locale: self.locale })
      },
      lookup(name, values) {
        return self.i18n.lookup(name, { ...values, locale: self.locale })
      }
    }))
    .actions(self => ({
      afterCreate() {
        self.i18n.defaultLocale = 'en'
        self.i18n.fallbacks = true
        self.i18n.translations = localTranslations

        loadIntlPolyfill('en')
      },

      update: flow(function * (country, locales, locale = locales[0]) {
        self.i18n.translations = yield getTranslations({ country, locales })
        yield loadIntlPolyfill(country)
        self.availableLocales = locales
        self.setLocale(locale)
      }),

      setLocale(locale) {
        self.i18n.locale = locale
        self.locale = locale

        const m = locale.match(/([a-z]+)_en$/)
        if (m && m[1]) {
          self.i18n.locales[locale] = [locale, m[1], 'en']
        } else {
          self.i18n.locales[locale] = [locale, 'en']
        }

        const languageCode = languageCodes[locale]
        LuxonSettings.defaultLocale = languageCode

        if (Platform.OS === 'web') {
          window.document.documentElement.lang = languageCode
        }

        const root = getRoot(self)
        unprotect(root)
        updateLuxonInstances(root, languageCode)
        protect(root)
      }
    }))

  return i18nStore
}

export default createI18nStore
