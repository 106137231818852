/**
 * Returns URL to the locale files for specific country
 * @callback localesUrl
 * @param {string} Country
 */
/**
 * Debugging function
 * @callback debug
 * @param {string} Debug message
 * @param {object} Additional debugging data
 */
/**
 * Creates getTranslation function for fetching translations
 * @param {string} mapKey Project name from h2 phraseapp.yml
 * @param {localesUrl} localesUrl Function that returns URL to locale files for specific country
 * @param {object} localTranslations Inlined translations for minimal UI in case of an error
 * @param debug
 * @returns {function({country?: *}): null}
 */
const createGetTranslations = ({ mapKey, localesUrl, localTranslations = {}, debug = () => {} }) => {
  const fetchJson = async url => {
    try {
      const response = await fetch(url)
      return response.json()
    } catch (e) {
      console.error('could not fetch ' + url)
      throw e
    }
  }

  let lastSyncAt = 0
  let cacheKey = null
  let result = null

  async function getRemoteTranslations({ country, locales }) {
    const translationsCountry = country || 'de'
    const localesToFetch = locales.concat(['en'])

    debug('fetching translations', { translationsCountry })

    try {
      const mapFile = await fetchJson(localesUrl(translationsCountry) + '/map.json')

      if (!mapFile || !mapFile[mapKey]) {
        debug('invalid map file format', { mapFile })
        throw new Error(`Unable to fetch translations file: ${JSON.stringify(mapFile)}`)
      }

      const map = mapFile[mapKey]

      const promises = localesToFetch.map(async locale => {
        const local = localTranslations[locale]
        if (map[locale]) {
          const url = localesUrl(translationsCountry) + `/${map[locale]}`
          const phrase = map[locale] ? await fetchJson(url) : {}
          return Object.assign({}, local, phrase)
        }
        return local
      })

      const translations = await Promise.all(promises)

      return localesToFetch.reduce((resources, locale, index) => {
        resources[locale] = translations[index]
        return resources
      }, {})
    } catch (error) {
      lastSyncAt = 0
      debug('unable to fetch translations', error)
      console.error(error)
      return localTranslations
    }
  }

  return function getTranslations({ country, locales }) {
    if (Date.now() - lastSyncAt > 60000 || country !== cacheKey) {
      lastSyncAt = Date.now()
      cacheKey = country
      result = getRemoteTranslations({ country, locales })
    }
    return result
  }
}

export default createGetTranslations
